<template>
    <form class="l-stack l-gap-3" @submit.prevent="handleSubmit">
        <div class="l-stack l-gap-2">
            <Multiselect
                v-model="condition"
                class="multiselect-redesigned"
                :options="conditionOptions"
                :custom-label="option => $t(`shared.measurements.${option}`)"
                :show-labels="false"
                :placeholder="$t('conditionPlaceholder')"
                @input="clearValue"
            >
                <template #noResult>
                    {{ $t('shared.noSearchResult') }}
                </template>
            </Multiselect>

            <div
                v-if="condition === 'geofence'"
                class="l-inline l-gap-1 l-center-v conditions-row"
            >
                <Multiselect
                    v-model="operator"
                    class="multiselect-redesigned"
                    :options="geofenceOptions"
                    :custom-label="option => $t(option)"
                    :show-labels="false"
                    :searchable="false"
                    :placeholder="$t('position')"
                />

                <Multiselect
                    v-model="value"
                    class="multiselect-redesigned"
                    :options="getLocationsSortedByName"
                    track-by="id"
                    label="name"
                    :show-labels="false"
                    :placeholder="$t('location')"
                >
                    <template #noResult>
                        {{ $t('shared.noSearchResult') }}
                    </template>
                </Multiselect>
            </div>

            <div
                v-else-if="condition === 'state'"
                class="l-inline l-gap-1 l-center-v conditions-row"
            >
                <Multiselect
                    v-model="operator"
                    class="multiselect-redesigned"
                    :options="stateChannelOptions"
                    :custom-label="option => $t(option)"
                    :show-labels="false"
                    :searchable="false"
                    :placeholder="$t('channel')"
                />

                <Multiselect
                    v-model="value"
                    class="multiselect-redesigned"
                    :options="stateOptions"
                    :custom-label="option => $t(option)"
                    :show-labels="false"
                    :searchable="false"
                    :placeholder="$t('value')"
                />
            </div>

            <div
                v-else-if="condition === 'offline'"
                class="l-inline l-gap-1 l-center-v conditions-row"
            >
                <base-input
                    v-model.number="timeValue"
                    style="width: 100%;"
                    type="number"
                    :block="false"
                    :placeholder="$t('value')"
                >
                    <template #postfix>
                        {{ $t('time-h') }}
                    </template>
                </base-input>
            </div>

            <div
                v-else-if="condition"
                class="l-inline l-gap-1 l-center-v conditions-row"
            >
                <Multiselect
                    v-model="operator"
                    class="multiselect-redesigned"
                    :options="operatorOptions"
                    :custom-label="option => operatorTransalations[option]"
                    :show-labels="false"
                    :searchable="false"
                    :placeholder="$t('operator')"
                />

                <base-input
                    v-model.number="$v.value.$model"
                    type="number"
                    :block="false"
                    :placeholder="$t('value')"
                >
                    <template #postfix>
                        {{ units[condition] }}
                    </template>
                </base-input>
            </div>
        </div>

        <p v-show="$v.value.$dirty && $v.value.$invalid" class="form-error">
            {{ $t('invalidValue') }}
        </p>

        <div class="l-inline l-spread l-center-v">
            <span class="clickable" @click="$emit('close')">
                {{ $t('cancel') }}
            </span>

            <base-button size="small" type="submit" :disabled="isDisabled">
                {{ $t('save') }}
            </base-button>
        </div>
    </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'

import { measurementHelper } from '@/utils'

import BaseButton from './BaseButton'
import BaseInput from './BaseInput'

export default {
    name: 'NotificationConditionForm',
    components: {
        BaseButton,
        BaseInput,
        Multiselect,
    },
    props: {
        initialValue: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {
            condition: null,
            conditionOptions: [
                'battery',
                'battery_voltage',
                'co2',
                'distance',
                'fill_level',
                'geofence',
                'humidity',
                'state',
                'temperature',
                'voc',
            ],
            geofenceOptions: ['inside', 'outside'],
            stateOptions: ['on', 'off'],
            stateChannelOptions: ['d1', 'd2'],
            noOperatorConditionOptions: ['offline'],
            operator: null,
            operatorOptions: ['<', '=', '>'],
            operatorTransalations: {
                '<': this.$t('lt'),
                '=': this.$t('eq'),
                '>': this.$t('gt'),
            },
            timeOptions: {
                h: 60 * 60,
                min: 60,
            },
            timeUnit: 'h',
            timeValue: 24,
            value: null,
            units: measurementHelper.units,
        }
    },
    validations() {
        return {
            value: {
                required,
            },
        }
    },
    computed: {
        ...mapGetters('authentication', ['isStaff']),
        ...mapGetters('locations', ['getLocationsSortedByName']),
        isDisabled() {
            return (
                this.$v.value.$error ||
                !this.condition ||
                (!this.operator &&
                    !this.noOperatorConditionOptions.includes(
                        this.condition
                    )) ||
                (!this.value && !this.timeValue)
            )
        },
    },
    watch: {
        initialValue() {
            this.setInitialValue()
        },
    },
    mounted() {
        if (this.isStaff) {
            this.conditionOptions = [
                ...this.conditionOptions,
                'lower_loop',
                'potential',
                'upper_loop',
                'probe_state',
                'water_level',
                'tau',
                'offline',
            ].sort()
        }

        this.setInitialValue()
    },
    methods: {
        clearValue() {
            this.operator = null
            this.value = null
            this.$v.$reset()
        },
        handleSubmit() {
            let payload

            if (this.condition === 'geofence') {
                payload = {
                    type: 'geofence',
                    position: this.operator,
                    locationId: this.value.id,
                }
            } else if (this.condition === 'state') {
                payload = {
                    type: 'state',
                    state: this.value === 'on',
                    value: this.operator,
                    name: 'On',
                }
            } else if (this.condition === 'offline') {
                payload = {
                    type: 'offline',
                    seconds: Math.round(
                        this.timeValue * this.timeOptions[this.timeUnit]
                    ),
                    user_unit: this.timeUnit,
                }
            } else {
                payload = {
                    type: 'threshold',
                    operator: this.operator,
                    threshold:
                        this.condition === 'fill_level'
                            ? this.value / 100
                            : this.value,
                    value: this.condition,
                }
            }

            this.$emit('close', payload)
        },
        setInitialValue() {
            switch (this.initialValue?.type) {
                case 'geofence':
                    this.condition = this.initialValue.type
                    this.operator = this.initialValue.position
                    this.value = this.getLocationsSortedByName.find(
                        item => item.id === this.initialValue.locationId
                    )
                    break
                case 'state':
                    this.condition = this.initialValue.type
                    this.operator = this.initialValue.value
                    this.value = this.initialValue.state ? 'on' : 'off'
                    break
                case 'threshold':
                    this.condition = this.initialValue.value
                    this.operator = this.initialValue.operator
                    this.value =
                        this.condition === 'fill_level'
                            ? this.initialValue.threshold * 100
                            : this.initialValue.threshold
                    break
                case 'offline':
                    this.condition = this.initialValue.type
                    this.timeUnit = this.initialValue.user_unit
                    this.timeValue =
                        this.initialValue.seconds /
                        this.timeOptions[this.timeUnit]
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "cancel": "Cancel",
        "channel": "Channel",
        "conditionPlaceholder": "Choose Measurement",
        "d1": "Channel 1",
        "d2": "Channel 2",
        "eq": "Equals",
        "gt": "Greater than",
        "inside": "Inside",
        "invalidValue": "Invalid value",
        "location": "Location",
        "lt": "Less than",
        "off": "Off",
        "on": "On",
        "operator": "Operator",
        "outside": "Outside",
        "position": "Position",
        "save": "Save",
        "unit": "Unit",
        "value": "Value",
        "time-h": "Hours",
        "time-min": "Minutes"
    },
    "de": {
        "cancel": "Abbrechen",
        "channel": "Kanal",
        "conditionPlaceholder": "Messwert auswählen",
        "d1": "Kanal 1",
        "d2": "Kanal 2",
        "eq": "Gleich",
        "gt": "Grösser als",
        "inside": "Innerhalb",
        "invalidValue": "Ungültiger Wert",
        "location": "Standort",
        "lt": "Kleiner als",
        "off": "Aus",
        "on": "Ein",
        "operator": "Bedingung",
        "outside": "Ausserhalb",
        "position": "Position",
        "save": "Speichern",
        "unit": "Einheit",
        "value": "Wert",
        "time-h": "Stunden",
        "time-min": "Minuten"
    },
    "it": {
        "cancel": "Cancellare",
        "channel": "Channel",
        "conditionPlaceholder": "Scegliere la misura",
        "d1": "Channel 1",
        "d2": "Channel 2",
        "eq": "equivale a",
        "gt": "maggiore di",
        "inside": "All'interno di",
        "invalidValue": "Valore non valido",
        "location": "Posizione",
        "lt": "Meno di",
        "off": "Off",
        "on": "On",
        "operator": "Operatore",
        "outside": "All'esterno di",
        "position": "Sosizione",
        "save": "Salve",
        "unit": "Unità",
        "value": "Valore",
        "time-h": "Ore",
        "time-min": "Minuti"
    }
}
</i18n>

<style lang="scss" scoped>
.conditions-row {
    &:first-child {
        min-width: 60%;
    }
}

.form-error {
    margin-bottom: 0;
}
</style>
