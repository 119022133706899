<template>
    <div class="card">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'Card',
}
</script>

<style lang="scss" scoped>
.card {
    padding: 8px;
    background-color: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    border: 1px solid #fff;
    border-radius: 8px;
    font-size: 14px;
    color: $color-text-new;
}
</style>
