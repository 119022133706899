<template>
    <form class="l-stack l-gap-3" @submit.prevent="handleSubmit">
        <div class="l-stack l-gap-2">
            <Multiselect
                v-model="type"
                class="multiselect-redesigned"
                :options="typeOptions"
                :custom-label="option => $t(option)"
                :show-labels="false"
                :placeholder="$t('typePlaceholder')"
            >
                <template #noResult>
                    {{ $t('shared.noSearchResult') }}
                </template>
            </Multiselect>

            <label v-if="type === 'EMAIL' || type === 'SMS'">
                <p class="form-label">
                    {{ $t('recipient') }}
                </p>

                <p v-if="$v.target.$error" class="form-error">
                    {{ $t('invalid' + type) }}
                </p>

                <base-input
                    v-model="$v.target.$model"
                    :block="false"
                    :placeholder="$t('targetPlaceholder' + type)"
                />
            </label>

            <info-box v-if="type === 'EMAIL_PRIMARY'">
                {{ $t('EMAIL_PRIMARY_helptext') }}

                <strong>{{ additionalData.userEmail || $t('empty') }}</strong>
            </info-box>

            <info-box v-if="type === 'SMS_PRIMARY'">
                {{ $t('SMS_PRIMARY_helptext') }}

                <strong>{{ additionalData.userSms || $t('empty') }}</strong>
            </info-box>

            <info-box v-if="type === 'SMS' || type === 'SMS_PRIMARY'">
                {{ $t('smsDisclaimer') }}
            </info-box>
        </div>

        <div class="l-inline l-spread l-center-v">
            <span class="clickable" @click="$emit('close')">
                {{ $t('cancel') }}
            </span>

            <base-button size="small" type="submit" :disabled="isDisabled">
                {{ $t('save') }}
            </base-button>
        </div>
    </form>
</template>

<script>
import { helpers, email } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'

import BaseButton from './BaseButton'
import BaseInput from './BaseInput'
import InfoBox from '../InfoBox.vue'

const phoneNumber = helpers.regex('phone', /^\+?[\d\s]+$/)

export default {
    name: 'NotificationActionForm',
    components: {
        BaseButton,
        BaseInput,
        InfoBox,
        Multiselect,
    },
    props: {
        additionalData: {
            type: Object,
            default: () => {},
        },
        initialValue: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {
            type: null,
            typeOptions: ['EMAIL_PRIMARY', 'SMS_PRIMARY', 'EMAIL', 'SMS'],
            target: null,
        }
    },
    computed: {
        isDisabled() {
            return (
                !this.type ||
                ((this.type === 'EMAIL' || this.type === 'SMS') &&
                    !this.target) ||
                this.$v.$invalid
            )
        },
    },
    watch: {
        initialValue() {
            this.setInitialValue()
        },
    },
    validations() {
        return {
            target: {
                ...(this.type === 'EMAIL' && { email }),
                ...(this.type === 'SMS' && { phoneNumber }),
            },
        }
    },
    mounted() {
        this.setInitialValue()
    },
    methods: {
        handleSubmit() {
            const payload =
                this.type === 'EMAIL' || this.type === 'SMS'
                    ? { type: this.type, target: this.target }
                    : { type: this.type }

            this.$emit('close', payload)
        },
        setInitialValue() {
            this.type = this.initialValue?.type
            this.target = this.initialValue?.target
        },
    },
}
</script>

<i18n>
{
    "en": {
        "EMAIL": "E-mail to different address",
        "EMAIL_PRIMARY": "E-Mail to me",
        "SMS": "SMS to different number",
        "SMS_PRIMARY": "SMS to me",
        "cancel": "Cancel",
        "invalidEMAIL": "Invalid E-Mail",
        "invalidSMS": "Invalid phone number",
        "recipient": "Recipient",
        "save": "Save",
        "smsDisclaimer": "Additional charges are incurred when using this service.",
        "targetPlaceholderEMAIL": "Enter E-Mail Address",
        "targetPlaceholderSMS": "+41 79 123 45 67",
        "typePlaceholder": "Notification channel",
        "EMAIL_PRIMARY_helptext": "Goes to all email addresses from the owner's user profile.",
        "SMS_PRIMARY_helptext": "Goes to all phone numbers from the owner's user profile.",
        "empty": "(empty)"
    },
    "de": {
        "EMAIL": "E-Mail an abweichende Adresse",
        "EMAIL_PRIMARY": "E-Mail an mich",
        "SMS": "SMS an abweichende Nummer",
        "SMS_PRIMARY": "SMS an mich",
        "cancel": "Abbrechen",
        "invalidEMAIL": "Ungültige E-Mail",
        "invalidSMS": "Ungültige Telefonnummer",
        "recipient": "Empfänger",
        "save": "Speichern",
        "smsDisclaimer": "Für die Nutzung dieser Dienstleistung fallen zusätzliche Kosten an.",
        "targetPlaceholderEMAIL": "E-Mail Adresse eingeben",
        "targetPlaceholderSMS": "+41 79 123 45 67",
        "typePlaceholder": "Benachrichtigungskanal",
        "EMAIL_PRIMARY_helptext": "Geht an alle E-Mail Adressen aus Ihrem Benutzerprofil:",
        "SMS_PRIMARY_helptext": "Geht an alle Telefonnummern aus ihrem Benutzerprofil:",
        "empty": "(leer)"
    },
    "it": {
        "EMAIL": "E-mail a un indirizzo diverso",
        "EMAIL_PRIMARY": "E-mail a me",
        "SMS": "SMS a un numero diverso",
        "SMS_PRIMARY": "SMS a me",
        "cancel": "Cancellare",
        "invalidEMAIL": "E-Mail non valido",
        "invalidSMS": "Numero di telefono non valido",
        "recipient": "Destinatario",
        "save": "Salve",
        "smsDisclaimer": "Ci sono costi aggiuntivi per l'utilizzo di questo servizio.",
        "targetPlaceholderEMAIL": "Inserire l'indirizzo e-mail",
        "targetPlaceholderSMS": "+41 79 123 45 67",
        "typePlaceholder": "Canale di notifica",
        "EMAIL_PRIMARY_helptext": "Va a tutti gli indirizzi e-mail dal profilo utente del proprietario.",
        "SMS_PRIMARY_helptext": "Va a tutti i numeri di telefono del profilo utente del proprietario.",
        "empty": "(vuoto)"
    }
}
</i18n>

<style lang="scss" scoped>
.information-box {
    & + .information-box {
        margin-top: 0;
    }
}
</style>
