<template>
    <div class="rule-edit-view">
        <Header :title="$t('title')" />

        <div class="l-stack l-gap-4 rule-edit-view__container">
            <div class="l-stack l-gap-2">
                <h2>{{ $t('name') }}</h2>

                <base-input
                    v-model="name"
                    :placeholder="$t('namePlaceholder')"
                />
            </div>

            <notification-edit-fragment
                v-model="conditions"
                type="condition"
                :title="$t('condition')"
                :placeholder="$t('conditionPlaceholder')"
                :placeholder-hint="$t('conditionPlaceholderHint')"
            />

            <notification-edit-fragment
                v-model="actions"
                type="action"
                :title="$t('action')"
                :placeholder="$t('actionPlaceholder')"
                :placeholder-hint="$t('actionPlaceholderHint')"
                :additional-data="{ userEmail, userSms }"
            />

            <notification-edit-fragment
                v-model="targets"
                type="target"
                :title="$t('target')"
                :placeholder="$t('targetPlaceholder')"
                :placeholder-hint="
                    $t('targetPlaceholderHint', [$t('shared.assets')])
                "
            />

            <div v-if="isAdminOrStaff" class="l-stack l-gap-2">
                <h2>{{ $t('adminsettings') }}</h2>

                <card class="l-gap-2 l-stack rule-edit-view__admin-settings">
                    <div class="l-inline l-gap-2 l-center-v">
                        <toggle-button
                            v-model="adminRule"
                            :width="40"
                            :height="24"
                            :speed="150"
                            css-colors
                            class="redesigned"
                            sync
                        />

                        <label>{{ $t('adminrule') }}</label>
                    </div>

                    <div class="l-inline l-gap-2 l-center-v">
                        <toggle-button
                            v-model="set_a_delay"
                            :width="40"
                            :height="24"
                            :speed="150"
                            css-colors
                            class="redesigned"
                            sync
                        />

                        <label>{{ $t('delay') }}</label>

                        <base-input
                            v-show="set_a_delay"
                            v-model="delay"
                            :placeholder="$t('value')"
                            type="number"
                            style="width: 100%;"
                            :block="false"
                        >
                            <template #postfix>
                                {{ $t('time-h') }}
                            </template>
                        </base-input>
                    </div>

                    <div class="l-inline l-gap-2 l-center-v">
                        <toggle-button
                            v-model="ownerCustom"
                            :width="40"
                            :height="24"
                            :speed="150"
                            css-colors
                            class="redesigned"
                            sync
                            @input="owner = null"
                        />

                        <label>{{ $t('owner') }}</label>

                        <v-multiselect
                            v-if="ownerCustom"
                            v-model="owner"
                            class="multiselect-redesigned"
                            :options="usersSortedByUsername"
                            :custom-label="option => option.username"
                            :show-labels="false"
                            :placeholder="$t('ownerPlaceholder')"
                        >
                            <template v-slot:noResult>{{
                                $t('shared.noSearchResult')
                            }}</template>
                        </v-multiselect>
                    </div>
                </card>
            </div>

            <base-button v-if="canSubmit" @click="handleSubmit">
                {{ $t('save') }}
            </base-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ToggleButton } from 'vue-js-toggle-button'
import VMultiselect from 'vue-multiselect'

import { httpHelper } from '@/utils'
import { userService } from '@/service/user.service'

import BaseButton from '../../redesigned/BaseButton'
import BaseInput from '../../redesigned/BaseInput'
import Header from '../../redesigned/Header'
import NotificationEditFragment from '../../redesigned/NotificationEditFragment'
import Card from '@/components/redesigned/Card'

export default {
    name: 'RuleEditView',
    components: {
        BaseButton,
        BaseInput,
        Card,
        Header,
        NotificationEditFragment,
        ToggleButton,
        VMultiselect,
    },
    props: {
        initialAdmin: {
            type: Boolean,
            default: false,
        },
        initialConditions: {
            type: Array,
            default: () => [],
        },
        initialName: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            actions: [],
            adminRule: false,
            conditions: [],
            currentId: null,
            name: '',
            targets: {},
            owner: null,
            ownerCustom: false,
            userEmail: '',
            userSms: '',
            set_a_delay: false,
            delay: 7,
        }
    },
    computed: {
        ...mapGetters('authentication', [
            'getAlertsettingsEmailRecipients',
            'getAlertsettingsSMSRecipients',
            'getCurrentUserId',
            'isAdminOrStaff',
        ]),
        ...mapGetters('user', ['usersSortedByUsername']),
        canSubmit() {
            return (
                this.actions.length &&
                this.conditions.length &&
                this.name.length &&
                (this.targets.assets?.type === 'all' ||
                    this.targets.assets?.length ||
                    this.targets.locations?.length ||
                    this.targets.types?.length)
            )
        },
    },
    async mounted() {
        this.currentId = this.$router.currentRoute.params.id

        if (this.currentId) {
            const { data } = await httpHelper.get(
                `/alertrules/${this.currentId}/`
            )

            this.name = data.name
            this.actions = data.notification_channels
            this.conditions = data.conditions.conditions
            this.targets = {}
            this.adminRule = !!data.admin
            this.set_a_delay = data.delay > 0
            this.delay = data.delay

            if (data.user !== this.getCurrentUserId) {
                this.owner = this.usersSortedByUsername.find(
                    item => item.id === data.user
                )
                this.ownerCustom = !!this.owner
            }

            if (data.alert_by_sms) {
                this.actions.unshift({
                    type: 'SMS_PRIMARY',
                })
            }

            if (data.alert_by_email) {
                this.actions.unshift({
                    type: 'EMAIL_PRIMARY',
                })
            }

            if (data.all_assets) {
                this.targets.assets = []
                this.targets.assets.type = 'all'
            }

            if (data.assets?.length) {
                this.targets.assets = data.assets
                this.targets.assets.type = 'assets'
            }

            if (data.locations?.length) {
                this.targets.locations = data.locations
                this.targets.locations.type = 'locations'
            }

            if (data.asset_types?.length) {
                this.targets.types = data.asset_types
                this.targets.types.type = 'types'
            }
        } else if (
            this.initialAdmin ||
            this.initialName ||
            this.initialConditions.length
        ) {
            this.$nextTick(() => {
                this.adminRule = this.initialAdmin
                this.name = this.initialName
                this.conditions = this.initialConditions
            })
        }

        if (this.owner) {
            const {
                email_recipients,
                sms_recipients,
            } = await userService.fetchUserAlertSettings(this.owner.id)
            this.userEmail = email_recipients.join('; ')
            this.userSms = sms_recipients.join('; ')
        } else {
            this.userEmail = this.getAlertsettingsEmailRecipients.join('; ')
            this.userSms = this.getAlertsettingsSMSRecipients.join('; ')
        }
    },
    methods: {
        async handleSubmit() {
            const payload = {
                active: true,
                admin: this.adminRule,
                user: this.owner?.id || this.getCurrentUserId,
                delay: this.set_a_delay ? this.delay : 0,
                name: this.name,
                conditions: {
                    version: 1,
                    matchMode: 'all',
                    conditions: this.conditions,
                },
                alert_by_email: this.actions.some(
                    item => item.type === 'EMAIL_PRIMARY'
                ),
                alert_by_sms: this.actions.some(
                    item => item.type === 'SMS_PRIMARY'
                ),
                notification_channels: this.actions.filter(item => item.target),
                all_assets: this.targets.assets?.type === 'all',
                assets: this.targets.assets || [],
                asset_types: this.targets.types || [],
                locations: this.targets.locations || [],
            }

            if (this.currentId) {
                await httpHelper.put(`/alertrules/${this.currentId}/`, payload)
            } else {
                await httpHelper.post('/alertrules/', payload)
            }

            this.$router.push('/rules')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "action": "Then",
        "actionPlaceholder": "No Notification",
        "actionPlaceholderHint": "Configure Notification",
        "adminrule": "Admin Rule",
        "condition": "If",
        "conditionPlaceholder": "No Condition",
        "conditionPlaceholderHint": "Configure Condition",
        "delay": "Delay",
        "name": "Name",
        "namePlaceholder": "Rule without Name",
        "save": "Save",
        "target": "Apply to",
        "targetPlaceholder": "No Application",
        "targetPlaceholderHint": "Choose {0} or Group",
        "time-h": "Hours",
        "title": "Create new Rule",
        "owner": "Assign",
        "ownerPlaceholder": "Select owner",
        "value": "Value",
        "adminsettings": "Admin Settings"
    },
    "de": {
        "action": "Dann",
        "actionPlaceholder": "Keine Benachrichtigung",
        "actionPlaceholderHint": "Benachrichtigung einstellen",
        "adminrule": "Admin Regel",
        "condition": "Wenn",
        "conditionPlaceholder": "Keine Bedingung",
        "conditionPlaceholderHint": "Bedingung einstellen",
        "delay": "Verzögerung",
        "name": "Name",
        "namePlaceholder": "Unbenannte Regel",
        "save": "Speichern",
        "target": "Anwenden auf",
        "targetPlaceholder": "Keine Anwendung",
        "targetPlaceholderHint": "{0} oder Gruppen auswählen",
        "time-h": "Stunden",
        "title": "Neue Regel erstellen",
        "owner": "Zuweisen",
        "ownerPlaceholder": "Benutzer",
        "value": "Wert",
        "adminsettings": "Admin Einstellungen"
    },
    "it": {
        "action": "Dann",
        "actionPlaceholder": "Keine Benachrichtigung",
        "actionPlaceholderHint": "Benachrichtigung einstellen",
        "adminrule": "Admin Regel",
        "condition": "Wenn",
        "conditionPlaceholder": "Keine Bedingung",
        "conditionPlaceholderHint": "Bedingung einstellen",
        "delay": "Verzögerung",
        "name": "Name",
        "namePlaceholder": "Unbenannte Regel",
        "save": "Speichern",
        "target": "Anwenden auf",
        "targetPlaceholder": "Keine Anwendung",
        "targetPlaceholderHint": "{0} oder Gruppen auswählen",
        "time-h": "Ore",
        "title": "Neue Regel erstellen",
        "owner": "assegnare",
        "ownerPlaceholder": "Selezionare",
        "value": "Valore",
        "adminsettings": "Impostazioni amministratore"
    }
}
</i18n>

<style lang="scss" scoped>
.rule-edit-view {
    &__container {
        margin: 48px auto;
        max-width: 340px;
    }

    &__admin-settings {
        div {
            height: 40px;
        }
    }

    h2 {
        font-weight: 700;
        font-size: 20px;
        color: $color-text-new;
    }
}
</style>
